import initialState from './initialState';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function detallesCompraConfigReducer(state = initialState.detallesConfig, action) {
    switch (action.type) {
        default:
            return state;
    }
}