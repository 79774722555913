import React, { Component } from 'react'
import { MDBBtn } from 'mdbreact';
import PropTypes from "prop-types";
class BttnPaymentApplePay extends Component {
    constructor() {
        super();
        this.state = {
          isMouseInside: false
        };
      }
    
      mouseEnter = () => {
        this.setState({ isMouseInside: true });
      }
    
      mouseLeave = () => {
        this.setState({ isMouseInside: false });
      }
    
    render() {
        const { showForm, disabled } = this.props
        return (
            <MDBBtn
                disabled={disabled}
                onMouseEnter={this.mouseEnter} 
                onMouseLeave={this.mouseLeave}
                color="none"
                style={{
                    // background: currentTheme.primary_color,
                    width: "100%",
                    // fontFamily: currentTheme.font_primary,
                    // borderRadius: currentTheme.buttons_border_radius,
                    // backgroundImage: this.state.isMouseInside ?  "-webkit-named-image(apple-pay-logo-black)" : "-webkit-named-image(apple-pay-logo-white)",
                    height: "46px",
                    backgroundSize: "54px 18px",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "50% 50%",
                    WebkitAppearance: "-apple-pay-button",
                    "ApplePayButtonType": "pay",
                }}
                onClick={showForm}
            >
            </MDBBtn>
        )
    }
}
BttnPaymentApplePay.propTypes = {
    currentTheme: PropTypes.object,
    showForm: PropTypes.func,
    disabled: PropTypes.bool,
}
export default BttnPaymentApplePay