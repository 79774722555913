import { CHANGE_IS_LOADING } from '../constants/actionTypes';

const initialState = true; // Estado inicial de isLoading

export default function loadingReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_IS_LOADING:
      return action.value;
    default:
      return state;
  }
}