import React, { Component } from 'react';
import { connect } from 'react-redux';
import propTypes from "prop-types";
import BttnPayment from '../PageBuilder/BttnPayment'
import { I18n } from 'react-i18nify';
import { asyncfetchOtherPaymentStatus } from '../../actions/serverAnswerActions';

export class BancolombiaPaymentComponent extends Component {

  sendData = () => {
    const { url } = this.props.payment;
    const { responseStatus, orderDetails } = this.props;
    responseStatus(url, orderDetails.data)
  }

  render() {
    const {typeOrder = "", currentTheme, disabled } = this.props;
    const { sendData } = this;
    return (<>
      <div className="full-centering">
        <BttnPayment 
          disabled={disabled}
          currentTheme={currentTheme}
          internationalization={I18n.t(`payment-action-button-${typeOrder}`)}
          showForm={() => {sendData()}} // onClick en el componente de botón
        />
      </div>
    </>);
  }
}

BancolombiaPaymentComponent.propTypes = {
  orderId: propTypes.string,
  currentTheme: propTypes.object,
  typeOrder: propTypes.string,
  color: propTypes.string,
  logo: propTypes.any,
  payment: propTypes.object,
  responseStatus: propTypes.any,
  orderDetails: propTypes.object,
  disabled: propTypes.bool,
}

const mapStateToProps = (state) => ({ //state, ownProps
  currentTheme: state.theme,
  orderDetails: state.detalles
});

const mapDispatchToProps = (dispatch) => ({
  responseStatus: (url, data) => dispatch(asyncfetchOtherPaymentStatus(url, data)),
})

const ConnectedBancolombiaPaymentComponent = connect(
  mapStateToProps, mapDispatchToProps
)(BancolombiaPaymentComponent)

export default ConnectedBancolombiaPaymentComponent;
