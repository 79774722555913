import React, { Component } from 'react';
import propTypes from "prop-types";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import { connect } from 'react-redux';
import {asyncfetchTransactionVerify, fetchTransactionRetry} from '../../actions/serverAnswerActions';
import {TRANSACTION_RETRY, TRANSACTION_VERIFY} from '../../constants/endpoints';
import I18n from 'react-i18nify/build/lib/I18n';
import ModalPaymentLoading from '../PageBuilder/ModalPaymentLoading';


class TransactionVerify extends Component {
    constructor() {
        super();
        this.state = {
            verify: '',
            modalLoading: false
        }
    }

    handleVerify = (e) => {
        this.setState({ verify: e.target.value})
    }

    doToggle = () => {
        this.setState((prevState) => {
            const newState = !prevState.modalLoading;
            return {modalLoading: newState}
        });
    }

    sendVerify = () => {
        let response = {
            otp: this.state.verify
        }
        this.doToggle()
        return this.props.responseVerify(response);
    }

    retryTr = () => {
        return this.props.retryTransaction();
    }

    render() {
        const { currentTheme } = this.props;
        const { modalLoading } = this.state;
        const { doToggle } = this

        return (
            <MDBContainer className="card card-noshadow-sm">
                <ModalPaymentLoading {...{modalLoading, doToggle}} />
                <MDBRow>
                    <MDBCol lg="7" style={{padding: '30px'}}>
                        <h3 style={{color: currentTheme.primary_color, fontWeight: '700'}}>{I18n.t("verifyEwallet")}</h3>
                        <MDBCol md="12" lg="10" style={{paddingLeft: '0'}}>
                            <input
                                required
                                id="OTP"
                                type="text"
                                name="otp"
                                maxLength={6}
                                className="form-control"
                                value={this.state.verify}
                                placeholder=""
                                onChange={value => this.handleVerify(value)}
                            />
                        </MDBCol>
                    </MDBCol>
                    <MDBCol lg="5" className="d-lg-flex" style={{ background: currentTheme.side_bar_color, padding: '30px' }}>
                        <MDBCol lg="6" className="py-4 equal-distribution">
                            <MDBBtn
                                size="lg"
                                color="none"
                                disabled={this.state.verify !== '' ? false : true}
                                style={{background: currentTheme.primary_color}}
                                onClick={this.sendVerify}
                                type="submit"
                            >
                                {I18n.t("validateCode")}
                            </MDBBtn>
                        </MDBCol>
                      <MDBCol lg="6" className="py-4 equal-distribution">
                            <MDBBtn
                                size="lg"
                                color="none"
                                style={{background: currentTheme.primary_color}}
                                onClick={this.retryTr}
                                type="submit"
                            >
                                {I18n.t("goBack")}
                            </MDBBtn>
                        </MDBCol>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        )
    }
}

TransactionVerify.propTypes = {
  payment: propTypes.object,
  currentTheme: propTypes.object,
  responseVerify: propTypes.func,
  retryTransaction: propTypes.func,
}

const mapStateToProps = (state) => ({ //state, ownProps
    currentTheme: state.theme,
});

const mapDispatchToProps = (dispatch, props) => {
    const { id } = props.match.params;
    return {
      responseVerify: (verify) => dispatch(asyncfetchTransactionVerify(`${TRANSACTION_VERIFY}${id}/`, verify)),
      retryTransaction: () => dispatch(fetchTransactionRetry(`${TRANSACTION_RETRY}${id}/`)),
    }
}

const ConnectedTransactionVerify = connect(
    mapStateToProps, mapDispatchToProps
)(TransactionVerify)

export default ConnectedTransactionVerify;
