import React from "react";
import PropTypes from "prop-types";

const decbin = (dec = 0, length = 8) => {
  var out = "";
  while (length--) out += (dec >> length) & 1;
  return out;
};

const getBinaryString = (data = "") => {
  let buff = new Buffer(data, "base64");
  let binaryText = "";
  buff.map((e) => {
    binaryText += decbin(e);
    return null;
  });
  return binaryText;
};

const makecode = (ctx, codeBinary, rows, size) => {
  var b = codeBinary;
  var s = Math.floor(size / rows);
  ctx.fillStyle = "white";
  ctx.clearRect(0, 0, size, size);
  for (var i = 0; i < rows; i++) {
    for (var j = 0; j < rows; j++) {
      var c = b[i * rows + j];
      switch (c) {
        case "0":
          ctx.fillStyle = "black";
          break;
        case "1":
          ctx.fillStyle = "white";
          break;
        default:
          ctx.fillStyle = "red";
          break;
      }
      ctx.fillRect(j * s, i * s, s, s);
    }
  }
};

class QrCodeCreator extends React.Component {
  componentDidMount() {
    const { data = "", rows = 77, size = 616 } = this.props;
    const canvas = this.canvas;
    const ctx = canvas.getContext("2d");
    const binaryData = getBinaryString(data);
    makecode(ctx, binaryData, rows, size);
  }
  render() {
    const { size = 616 } = this.props;
    return (
      <canvas
        id="qrcode"
        ref={(el) => (this.canvas = el)}
        height={size}
        width={size}
        style={{ maxWidth: "100%", margin: "1em 0" }}
      ></canvas>
    );
  }
}

QrCodeCreator.propTypes = {
  data: PropTypes.string,
  rows: PropTypes.number,
  size: PropTypes.number,
};
export default QrCodeCreator;
