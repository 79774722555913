import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { BASE_PAGE_FORBIDEN_ACESS } from "../../constants/config";
import {I18n} from "react-i18nify";
import { MDBBtn, MDBCol, MDBContainer, MDBModalFooter, MDBRow } from 'mdbreact';
//import ConnectedJsonParsingPage from '../JsonParsingPage';
//const { I18n } = require('react-i18nify');

/* eslint-disable no-console */

class TransactionRespose extends Component {
  constructor(props) {
    super(props);

    const { serverAnswer, currentTheme } = this.props;
    const { data: { email = null, url_redirect = null, nit = null, router_redirect = null, instructions= null, bank_url=null } = {} } = serverAnswer
    const isACHType = email !== null && url_redirect !== null;
    const isSafety = url_redirect === null &&  router_redirect === null && bank_url !== null;

    this.state = {
      formFired: false,
      showsafety: 'none'
    };

    this.ACHType = isACHType ? {
      email, url_redirect, nit
    } : false;

    this.Safety = isSafety ? {
      bank_url, instructions, currentTheme
    } : false;

  }

  componentDidMount() {
    const { doRedirect, redirect, setRedirect, currentTheme } = this.props;

    if (currentTheme.close_window_on_end) {
      window.close();
      console.log("CERRADO POR TEMA")
    }

    if (this.ACHType) {
      !this.state.formFired && document.getElementById('ltiLaunchForm').submit();
      this.setState({ formFired: true });
    } else {
      if (this.Safety) {
        this.setState({ showsafety:'block' });
      }
      else {
        if (doRedirect) setRedirect(redirect)
        else window.location = redirect;
      }
    }
  }

  render() {

    /*
        const { serverAnswer, redirect, currentTheme, doRedirect } = this.props;
        const element = <ConnectedJsonParsingPage
            {...{
                jsonData: serverAnswer,
                titleKey: "response-description-title",
                descriptionKey: "response-description-description"
            }}
        >
            {
                doRedirect ?
                    <NavLink to={redirect} style={{ padding: 0 }}>
                        <MDBBtn color={currentTheme.color}>{I18n.t('response-description-continue')}</MDBBtn>
                    </NavLink> :
                    <MDBBtn href={redirect} color={currentTheme.color}>{I18n.t('response-description-continue')}</MDBBtn>
            }
        </ConnectedJsonParsingPage>
         */

    const ACTdata = this.ACHType ? this.ACHType : {}
    const Safetydata = this.Safety ? this.Safety :{}
    const { bank_url = "", instructions = [] , currentTheme = "" } = Safetydata
    const listInstructions = instructions.map((instruction) => <p key={instruction.step_number}><span><strong>
      {I18n.t('payment-title-step-safety')} {instruction.step_number}:</strong></span>{instruction.step_instruction}</p>
    )


    const { email = "", url_redirect = "", nit = null } = ACTdata
    return (<React.Fragment>
      {this.ACHType != false && (
        <form id="ltiLaunchForm" action={url_redirect} method="POST">
          <input type="hidden" name="email" value={email} />
          { nit && (
            <input type="hidden" name="nit" value={nit} />
          )}
          <input type="hidden" value="send" />
        </form>
      )}
        <MDBContainer
          className="card card-noshadow-sm"
          style={{ backgroundColor: currentTheme.primary_color_contrast, display: this.state.showsafety }}
        >
          <MDBRow>
            <MDBCol lg="12" className="px-4 pb-2 justify-content-center">

              < h5 style={{ color: currentTheme.primary_color }} className="pt-4">
                <strong>{I18n.t('payment-title-description-safety')}</strong>
              </h5>
            </MDBCol>
            <MDBCol lg="12" className="px-4 pb-2 justify-content-center">
              {listInstructions}
            </MDBCol>
          </MDBRow>

          <MDBModalFooter>
            <MDBRow className="full-centering">
              <MDBBtn color="none"
                      style={{ "background": currentTheme.primary_color }}
                      onClick={() => { window.location = bank_url}}>{I18n.t('payment-next-button')}</MDBBtn>
            </MDBRow>
          </MDBModalFooter>
        </MDBContainer>
    </React.Fragment>);
  }
}

const mapStateToProps = (state, props) => {
  const { id } = props.match.params;
  return {
    currentTheme: state.theme,
    serverAnswer: state.serverAnswer,
    redirect:
      state.serverAnswer && state.serverAnswer.data
        ? state.serverAnswer.data["router_redirect"] ||
        state.serverAnswer.data["url_redirect"] ||
        BASE_PAGE_FORBIDEN_ACESS
        : BASE_PAGE_FORBIDEN_ACESS,
    doRedirect: !!(
      state.serverAnswer &&
      state.serverAnswer.data &&
      state.serverAnswer.data["router_redirect"]
    ),
    orderId: id
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setRedirect: redirect => dispatch(push(redirect))
  };
};

TransactionRespose.propTypes = {
  currentTheme: PropTypes.object,
  serverAnswer: PropTypes.object,
  redirect: PropTypes.string,
  doRedirect: PropTypes.bool,
  setRedirect: PropTypes.any
};

const ConnectedTransactionRespose = connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionRespose);

export default ConnectedTransactionRespose;
