import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import LoadingOverlay from "react-loading-overlay";
import "font-awesome/css/font-awesome.min.css";

const SpinnerOverlay = props => (
  <LoadingOverlay
    {...props}
    spinner={
      <div className={classNames("-loading", {"active -active": props.loading})}>
        <div id="spinner" style={{top: "0%", left: "50%"}}>
          <i
            className="fa fa-spinner fa-pulse fa-3x fa-fw"
            style={{fontSize: 36, color: "#999999"}}
          />
        </div>
      </div>
    }
    styles={{
    overlay: base => ({
      ...base,
      background: "rgba(255, 255, 255, 0.7)",
      width:"110%",
      left:"-5%",
      color:"#999999"
    }),
    content: base => ({
      ...base,
      margin: "auto"
    })
  }}>
    {props.children}
  </LoadingOverlay>
);

SpinnerOverlay.propTypes = {
  loading: PropTypes.bool
};

export default SpinnerOverlay;
