import * as PAYMENTMETHODS from './../constants/paymentMethods';

export const filterPaymentMethod = (paymentMethodsUserObject = {}) => {
    const paymentMethodsFilter = [
      PAYMENTMETHODS.DEBITCARD,
      PAYMENTMETHODS.APPLEPAY,
      PAYMENTMETHODS.RAPPI,
      PAYMENTMETHODS.PIX,
      PAYMENTMETHODS.BANCOLOMBIA,
      PAYMENTMETHODS.QR,
      PAYMENTMETHODS.CREDIT,
      PAYMENTMETHODS.CASH,
      PAYMENTMETHODS.DEBIT,
      PAYMENTMETHODS.COLSUBSIDIO,
      PAYMENTMETHODS.EWALLET,
      PAYMENTMETHODS.LINK,
      PAYMENTMETHODS.LINKWITHFORM,
      PAYMENTMETHODS.PAYBETWEENACCOUNTS,
      PAYMENTMETHODS.CLICKTOPAY
    ];
    const keys = Object.keys(paymentMethodsUserObject);
    const regex = new RegExp(paymentMethodsFilter.join("|"), "i");
    let paymentMethods = []
    for (let i = 0; i < keys.length; i++) {
        let payChecking = paymentMethodsUserObject[keys[i]];
        if (regex.test(payChecking.name)) {
            paymentMethods.push(payChecking)
        }
    }
    return paymentMethods;
};
