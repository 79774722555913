import React, { Component } from 'react';
import { connect } from 'react-redux';
import propTypes from "prop-types";
import BttnPayment from '../PageBuilder/BttnPayment'
import { I18n } from 'react-i18nify';
import { asyncfetchRappiPaymentStatus, fetchGetServerData } from '../../actions/serverAnswerActions';
import { asyncChangeDetailsOrder } from '../../actions/detallesCompraActions';
import { INFORMATION_TRANSACTION } from '../../constants/endpoints';
export class RappiPaymentComponent extends Component {
      state = {
        isRappi: false
      }
      constructor() {
        super();
        this.state = {
          isRappi: false
        }
      }
      componentDidUpdate(prevProps) {
        const { rappiResponse } = this.props;
        const {isRappi} = this.state;
        if(prevProps.rappiResponse === rappiResponse && rappiResponse !== null && isRappi === true){
            this.showForm()
        }
       }
    showForm = () => {
      /* eslint-disable no-undef */
        const { rappiResponse, orderId } = this.props;
        fetchGetServerData(`${INFORMATION_TRANSACTION}${orderId}`)
                .then(response => response.json())
                .then((data) => {
                    if(data.data.order.status === "Expired" || data.data.order.status === "Payed") {
                      this.props.fecthOrderData(this.props.orderId);
                    }
                    else {
                      var pr =  new PagaRappi({
                        client_id : rappiResponse.data.terminal_id });
                      var checkout = pr.checkout({
                          checkout_id: rappiResponse.data.carrier_id,
                          auto_open: false,
                          type: "popup",
                          success_url: rappiResponse.data.redirect_rappi,
                          cancel_url: rappiResponse.data.redirect_rappi,
                      });
                      checkout.open();
                      this.setState({isRappi: false})
                    }
                })
    }
  sendData = () => {
    const { url } = this.props.payment;
    const { responseStatus, orderDetails } = this.props;
    responseStatus(url, orderDetails.data)
    this.setState({isRappi: true})
  }
  render() {
    const {typeOrder = "", currentTheme, disabled } = this.props;
    return (<>
      <div className="full-centering">
        <BttnPayment
          disabled={disabled}
          currentTheme={currentTheme}
          internationalization={I18n.t(`payment-action-button-${typeOrder}`)}
          showForm={() => {this.sendData()}} // onClick en el componente de botón
        />
      </div>
    </>);
  }
}
RappiPaymentComponent.propTypes = {
  orderId: propTypes.string,
  currentTheme: propTypes.object,
  typeOrder: propTypes.string,
  color: propTypes.string,
  logo: propTypes.any,
  payment: propTypes.object,
  responseStatus: propTypes.any,
  orderDetails: propTypes.object,
  rappiResponse: propTypes.object,
  fecthOrderData: propTypes.any,
  disabled: propTypes.bool,
}
const mapStateToProps = (state) => ({ //state, ownProps
  currentTheme: state.theme,
  orderDetails: state.detalles,
  rappiResponse: state.serverAnswer,
});
const mapDispatchToProps = (dispatch) => ({
  responseStatus: (url, data) => dispatch(asyncfetchRappiPaymentStatus(url, data)),
  fecthOrderData: (orderId) => {
    dispatch(asyncChangeDetailsOrder(orderId));
  }
})
const ConnectedRappiPaymentComponent = connect(
  mapStateToProps, mapDispatchToProps
)(RappiPaymentComponent)
export default ConnectedRappiPaymentComponent;