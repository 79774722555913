/* global __CCAPI_ENV__, __NOCCAPI_ENV__ */
import Themes from "../reducers/themes";

const {services_domain, domain} = Themes.getThemeByLocation();
export const NOCCAPI_URL = __NOCCAPI_ENV__ !== "undefined" ? `https://${__NOCCAPI_ENV__}.${services_domain || domain}` : `https://noccapi-stg.${services_domain || domain}`;
export const ORDER_DETAILS = NOCCAPI_URL + "/linktopay/get_order/";
export const RESPONSE_PAYMENTEZ_SEND_PAYCARD = NOCCAPI_URL + "/linktopay/pay_card/";
export const RESPONSE_PAYMENTEZ_SEND_PAYDEBITCARD = NOCCAPI_URL + "/linktopay/pay_debit/";
export const RESPONSE_PAYMENTEZ_SEND_PAYCLICKTOPAY = NOCCAPI_URL + "/linktopay/pay_clicktopay/";
export const INFORMATION_TRANSACTION = NOCCAPI_URL + "/linktopay/transaction/info/";
export const REFERENCE_TRANSACTION = NOCCAPI_URL + "/linktopay/card_reference/";
export const TRANSACTION_VERIFY = NOCCAPI_URL + "/linktopay/verify/"
export const TRANSACTION_RETRY = NOCCAPI_URL + "/linktopay/retry/"
export const PAYMENTEZ_API_ENV = __CCAPI_ENV__ !== "undefined" ? __CCAPI_ENV__ : "stg";
