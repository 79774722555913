import React from "react";
import PaymentWillBe, {getAvalibleColor} from "./PaymentWillBe";
import { I18n } from "react-i18nify";
import PropTypes from "prop-types";

const PaymentExchangeInfo = ({
  currency = null,
  currentTheme = {},
  rate = null,
  originalCurrency = null,
  originalAmount = null,
}) => {

  return (
    <div
    className="o-amount--exchange"
    style={{ color: getAvalibleColor(currentTheme) }}
  >
    <div className="o-amount--exchange-amount">
      <span>
        <PaymentWillBe
          amount={originalAmount}
          currency={originalCurrency}
        />
      </span>
    </div>
    <div className="o-amount--exchange-rate">
      <span className="o-amount--exchange-rate-title">
        {I18n.t("exchange-rate")}
      </span>
      <br/>
      <span className="o-amount--exchange-rate-amount">
        <PaymentWillBe
          amount={rate}
          currency={currency}
        />
        {" "}/ 1 {originalCurrency}
      </span>
    </div>
  </div>
  )
}

PaymentExchangeInfo.propTypes = {
  currency: PropTypes.any,
  currentTheme: PropTypes.any,
  rate: PropTypes.any,
  originalCurrency: PropTypes.any,
  originalAmount: PropTypes.any,
};


export default PaymentExchangeInfo;