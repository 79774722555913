import React from "react";
import PropTypes from "prop-types";
import { I18n } from "react-i18nify";
import { STATUS_CHECKOUT } from "../../constants/transactionStatus";
import PaymentWillBe from "./PaymentWillBe";
import PaymentExchangeInfo from "./PaymentExchangeInfo"
import PartialPaidInfo from "./PartialPaidInfo";
import Color from "color";
import { MDBIcon } from "mdbreact";

const PaymentInfoWillBe = ({
  amount,
  currency,
  currentTheme,
  status,
  paymentMethods,
  rate,
  originalCurrency,
  originalAmount,
  remainingAmount,

}) => {
  const statusList = [...STATUS_CHECKOUT];

  const selectStatus = status => {
    let result = null;
    statusList.map(statusElement => {
      if (
        RegExp(statusElement.list.join("|"), "i").test(status.toLowerCase())
      ) {
        result = statusElement;
      }
    });
    return result;
  };

  return (() => {
    const newStatus = selectStatus(status);
    return (
      <>
        <div
          className="o-amount mx-auto"
          style={{
            color: currentTheme?.primary_color || "inherit",
            fontFamily: currentTheme?.font_primary || "inherit"
          }}
        >
          {(status== "Pending" && remainingAmount && remainingAmount >0) && (
          <p style={{color: currentTheme.primary_color, fontSize: '23px'}} className="text-center">
            {I18n.t('partial_paid_link')}</p> )}
          {(!remainingAmount) &&(
            <PaymentWillBe
              className="o-amount--payment"
              amount={amount}
              currency={currency}
              theme={currentTheme}
            />
          )}
          {(remainingAmount && remainingAmount >0 ) &&(
          <PaymentWillBe
            className="o-amount--payment"
            amount={originalAmount}
            currency={currency}
            theme={currentTheme}
          />
          )}
        </div>
        {(originalAmount && originalCurrency && rate)
         && (
          <PaymentExchangeInfo
            currency={currency}
            currentTheme= {currentTheme}
            rate= {rate}
            originalCurrency= {originalCurrency}
            originalAmount= {originalAmount}
          />
         )}
        {(status != "Payed" && !rate && remainingAmount && remainingAmount > 0) && (
          <PartialPaidInfo
              paidAmount={Math.floor(originalAmount-remainingAmount)}
              currency={currency}
              currentTheme={currentTheme}
              pendingAmount= {remainingAmount}
            />)}
        <div
          className="full-centering text-center o-seudo-info"
          style={{
            color: Color(currentTheme.side_bar_color)
              .darken(0.2)
              .hex()
          }}
        >
          {newStatus || paymentMethods ? null : (
            <React.Fragment>
              <div>
                <MDBIcon far icon="comment-alt" size="4x" />
              </div>
              {I18n.t("payment-loading-description")}
            </React.Fragment>
          )}
        </div>
      </>
    );
  })();
};

PaymentInfoWillBe.propTypes = {
  amount: PropTypes.any,
  currency: PropTypes.any,
  paymentMethods: PropTypes.any,
  status: PropTypes.any,
  currentTheme: PropTypes.any,
  rate: PropTypes.any,
  originalCurrency: PropTypes.any,
  originalAmount: PropTypes.any,
  remainingAmount: PropTypes.any,
};

export default PaymentInfoWillBe;
