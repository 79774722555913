import { combineReducers } from 'redux';
import themeReducer from './themeReducer';
import detallesCompraReducer from './detallesCompraReducer';
import detallesCompraConfigReducer from './detallesCompraConfigReducer';
import serverAnswerReducer from './serverAnswerReducer';
import loadingReducer from './loadingReducer';

const rootReducer = combineReducers({
  theme: themeReducer,
  detalles: detallesCompraReducer,
  detallesConfig: detallesCompraConfigReducer,
  serverAnswer: serverAnswerReducer,
  isLoading: loadingReducer,
});

export default rootReducer;
