import React, { Component } from 'react'
import { MDBBtn } from 'mdbreact';
import PropTypes from "prop-types";

class BttnPayment extends Component {
    render() {
        const { currentTheme, internationalization, showForm, disabled } = this.props
        return (
            <MDBBtn 
                color="none"
                disabled={disabled}
                style={{ 
                    background: currentTheme.primary_color,
                    width: "100%",
                    fontFamily: currentTheme.font_primary,
                    borderRadius: currentTheme.buttons_border_radius,
                    '--colorHover': currentTheme.button_color_hover,
                    '--colorOpacity': currentTheme.button_opacity_hover,
                }}
                onClick={showForm}
            >
                {internationalization}
            </MDBBtn>
        )
    }
}

BttnPayment.propTypes = {
    currentTheme: PropTypes.object,
    internationalization: PropTypes.string,
    showForm: PropTypes.func,
    disabled: PropTypes.bool,
}

export default BttnPayment
