import React from "react";
import PaymentWillBe from "./PaymentWillBe";
import {I18n} from "react-i18nify";
import PropTypes from "prop-types";

const PartialPaidInfo = ({
                               currency = null,
                               currentTheme = {},
                               paidAmount = null,
                               pendingAmount = null,
                             }) => {

  return (
    <div
      className="o-amount"
      style={{color: "#058607E0"}}
    >
      <div className="o-amount--exchange-rate">
      <span className="o-amount--exchange-rate" style={{
        color: currentTheme.secondary_color}}>
       {I18n.t('paid_amount')}
      </span>
        <br/>
        <span className="o-amount--exchange-amount">
        <PaymentWillBe
          amount={paidAmount}
          currency={currency}
        />
      </span>
      </div>

      <div className="o-amount--exchange-rate">
      <span className="o-amount--exchange-rate-title" style={{
        color: currentTheme.secondary_color
      }}>
       {I18n.t('remaining_amount')}
      </span>
        <br/>
        <span className="o-amount--exchange-amount" style={{
          color: currentTheme.secondary_color}}>
        <PaymentWillBe
          amount={pendingAmount}
          currency={currency}
        />
      </span>
      </div>

    </div>
  )
}

PartialPaidInfo.propTypes = {
  currency: PropTypes.any,
  paidAmount: PropTypes.any,
  pendingAmount: PropTypes.any,
  currentTheme: PropTypes.any
};


export default PartialPaidInfo;
