import React, { Component } from 'react';
import { connect } from 'react-redux';
import propTypes from "prop-types";
import { asyncfetchOtherPaymentStatus } from '../../actions/serverAnswerActions';
import BttnPaymentApplePay from '../PageBuilder/BttnPaymentApplePay';
/*eslint no-console: ["error", { allow: ["warn", "error"] }] */
export class ApplePayPaymentComponent extends Component {
  constructor() {
    super();
    this.state = { 
      is_cancelled: false,
      applepay_data: {
        transaction_id: "",
        version: "",
        signature: "",
        data: "",
        ephemeral_public_key: "",
        public_key_hash: "",
        network: "",
        card_type: "",
        merchant_url: ""
      }
    }
  }

  sendData = async () => {
    await this.createSession();
    const { url } = this.props.payment;
    const {applepay_data} = this.state;
    if(!this.state.is_cancelled) {
      this.props.spinner();
      const { responseStatus } = this.props;
      const orderDetails = { ...this.props.orderDetails.data, ...{applepay_data: applepay_data} };
      responseStatus(url, orderDetails)
    }
    this.setState({is_cancelled: false});
  }

  createSession = async () => {
    const { url_session, terminal_id, commerce_id, country, config_extra, app_code } = this.props.payment;
    const { orderDetails } = this.props;
    const merchant_domain = window.location.hostname;
        try {
          const paymentMethodData = [
            {
              supportedMethods: "https://apple.com/apple-pay",
              data: {
                version: 3,
                merchantIdentifier: terminal_id,
                merchantCapabilities: config_extra.merchant_capabilities,
                supportedNetworks: config_extra.supported_networks,
                countryCode: country,
              },
            },
          ];

          const paymentDetails = {
            total: {
              label: commerce_id,
              amount: {
                value: orderDetails.data.order.amount,
                currency: orderDetails.data.order.currency,
              },
            },
          };
          // Define PaymentOptions
          const paymentOptions = {
            requestPayerName: false,
            requestBillingAddress: false,
            requestPayerEmail: false,
            requestPayerPhone: false,
            requestShipping: false,
            shippingType: "shipping",
          };
          // Create PaymentRequest
          const request = new PaymentRequest(
            paymentMethodData,
            paymentDetails,
            paymentOptions
          );

          request.onmerchantvalidation = async (event) => {
            // Call your own server to request a new merchant session.
            const { validationURL } = event;
            const merchantSessionPromise = fetch(
              url_session,
              {
                method: "POST",
                body: JSON.stringify({
                  "url_validate_session": validationURL,
                  "merchant_domain": merchant_domain,
                  "merchant": commerce_id,
                  "terminal_id": terminal_id,
                  "application_code": app_code
                }
                ), // data can be `string` or {object}!
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json;charset=UTF-8"
                },
              }
            )
              .then(async (res) => {
                if (!res.ok) {
                  throw new Error(`HTTP error! Status: ${res.status}`);
                }
          
                const responseData = await res.json();
                return responseData;
              })
              .catch((error) => {
                console.error("Error:", error);
                throw error; 
              });
            event.complete(merchantSessionPromise);
          };

          request.onpaymentmethodchange = event => {
            // Define PaymentDetailsUpdate based on the selected payment method.
            // No updates or errors needed, pass an object with the same total.
            const paymentDetailsUpdate = {
                'total': paymentDetails.total
            };
            event.updateWith(paymentDetailsUpdate);
        };

        request.onshippingoptionchange = event => {
            // Define PaymentDetailsUpdate based on the selected shipping option.
            // No updates or errors needed, pass an object with the same total.
            const paymentDetailsUpdate = {
                'total': paymentDetails.total
            };
            event.updateWith(paymentDetailsUpdate);
        };

        request.onshippingaddresschange = event => {
            // Define PaymentDetailsUpdate based on a shipping address change.
            const paymentDetailsUpdate = {
                "total": {
                    "label": commerce_id,
                    "amount": {
                        "value": orderDetails.data.order.amount,
                        "currency": orderDetails.data.order.currency
                    }
                }
            };
            event.updateWith(paymentDetailsUpdate);
        };

        const response = await request.show();
        const status = "success";
        await response.complete(status);
        this.setState(
          { 
            applepay_data: {
              transaction_id: response.details.token.paymentData.header.transactionId,
              version: response.details.token.paymentData.version,
              signature: response.details.token.paymentData.signature,
              data: response.details.token.paymentData.data,
              ephemeral_public_key: response.details.token.paymentData.header.ephemeralPublicKey,
              public_key_hash: response.details.token.paymentData.header.publicKeyHash,
              network: response.details.token.paymentMethod.network,
              card_type: response.details.token.paymentMethod.type,
              merchant_url: window.location.origin
            }
          }
        );
        } catch (e) {
          console.error("Hubo un error", e);
          this.setState({is_cancelled: true});
        }
  }



  render() {
    const {currentTheme, disabled } = this.props;
    const { sendData } = this;
    if(window.ApplePaySession){
      return (<>
        <div className="full-centering">
          <BttnPaymentApplePay 
            disabled={disabled}
            currentTheme={currentTheme}
            showForm={() => {sendData()}} // onClick en el componente de botón
          />
        </div>
      </>);
    }
    return <></>
  }
}

ApplePayPaymentComponent.propTypes = {
  orderId: propTypes.string,
  currentTheme: propTypes.object,
  color: propTypes.string,
  logo: propTypes.any,
  payment: propTypes.object,
  responseStatus: propTypes.any,
  orderDetails: propTypes.object,
  disabled: propTypes.bool,
  spinner: propTypes.func,
}

const mapStateToProps = (state) => ({ //state, ownProps
  currentTheme: state.theme,
  orderDetails: state.detalles
});

const mapDispatchToProps = (dispatch) => ({
  responseStatus: (url, data) => dispatch(asyncfetchOtherPaymentStatus(url, data)),
})

const ConnectedApplePayPaymentComponent = connect(
  mapStateToProps, mapDispatchToProps
)(ApplePayPaymentComponent)

export default ConnectedApplePayPaymentComponent;
