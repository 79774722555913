const { I18n } = require("react-i18nify");
import store from "store";

I18n.setTranslations({
  en: {
    currency: "Currency",
    paid_amount: "Paid",
    remaining_amount: "Remaining amount",
    amount_with_points: "Amount with points",
    original_amount: "Original Amount",
    original_currency: "Original Currency",
    url_reference: "Payment reference",
    expiration_days: "Expirations days",
    product_description: "Product description",
    order_description: "Product description",
    payment_method_type: "Payment method type",
    show: "Enter second password",
    "show-label":
      "For your security you must enter your second password for your multi-service card in the next window",
    pay: "Pay",
    partial_paid_link: "Link partial paid",
    "404-title": "Page not found",
    "404-message": "The redirect page could not be found",
    "order@user-name": "Name",
    "order@user-last_name": "Lastname",
    "order@user-email": "E-mail",
    "order@order-dev_reference": "Reference number",
    "order@order-description": "Description",
    "order@order-amount": "Purchase amount",
    "order@order-currency": "Currency",
    "pick-payment-method": "Payment methods",
    "pick-expired-link": "Link expired. Try to generate a new one",
    "pick-loading": "Cargando",
    "pick-payed-link": "Link paid",
    payment_will_be: "Your payment will be",
    "paymentez-conf_invalid_card_type_message":
      "Invalid credit card for this operation",
    "payment-title-pse": "Bank transfer",
    "payment-title-safetypay": "Pay with banktransfer",
    "payment-title-cash": "Pay with cash",
    "payment-title-pix": "Pay with pix",
    "payment-title-colsubsidio": "Colsubsidio",
    "payment-title-daviplata": "DaviPlata",
    "payment-method-card": "Pay with credit card",
    "payment-method-banktransfer": "Bank transfer",
    "payment-method-cash": "Generate payment code",
    "payment-description-paymentez": "Safety pay using your credit card",
    "payment-description-pse":
      "Paymentez® process local payments in computer games, web pages and movile applications in Latam. We are	conected to local credit card carriers in 6 countries, we offer	bank transfer and cash payments in every country to	provide	a wide payment gateway to our customers.",
    "payment-description-cash":
      "Paymentez® process local payments in computer games, web pages and movile applications in Latam. We are	conected to local credit card carriers in 6 countries, we offer	bank transfer and cash payments in every country to	provide	a wide payment gateway to our customers.",
    "payment-action-button-paymentez": "Pay with credit card",
    "payment-action-button-paymentez-debit": "Pay with debit card",
    "payment-action-button-paymentez-clicktopay": "Pay with Click To Pay",
    "payment-action-button-pse": "Pay with bank transfer",
    "payment-action-button-cash": "Pay with cash",
    "payment-action-button-qr": "Pay with Qr code",
    "payment-action-button-pay-between-accounts": "Pay between accounts",
    "payment-action-button-pix": "Pay with PIX",
    "payment-action-button-bancolombia": "Pay with bancolombia",
    "payment-action-button-safetypay": "Pay with banktransfer",
    "payment-action-button-rappi": "Pay with rappi",
    "payment-action-button-colsubsidio": "Colsubsidio",
    "payment-action-button-brilla": "Pay with Brilla",
    "payment-action-button-puntos_colombia": "Pay with Points colombia",
    "payment-action-button-colsubsidio-modal": "Continue",
    "payment-action-button-bradesco": "Pay with cash",
    "payment-action-button-daviplata": "DaviPlata",
    "payment-cancel-button": "Cancel",
    "payment-next-button": "Next",
    "payment-title-step-safety": "Step",
    "payment-title-description-safety":
      "You will be redirected to the payment page, remember to keep in mind the following:",
    "payment-title-description-cash":
      "You will be redirected to the cash payment page, remember to keep in mind the following:",
    "payment-cash-recommend-1":
      "A payment code will be generated for your purchase.",
    "payment-cash-recommend-2":
      "A payment place list will be appear, where you could make the payment.",
    "payment-cash-recommend-3":
      "Take the payment code of your purchase and the establishment code to make the payment.",
    "payment-cash-recommend-4":
      "You will get an e-mail when you finish your payment",
    "payment-title-description-qr": "In order to complete the payment, follow the steps below:",
    "payment-qr-recommend-1": "Access your mobile wallet, purse or bank app",
    "payment-qr-recommend-2": "Select the payment method available in the entity or wallet and scan the qr code",
    "payment-qr-recommend-3": "Confirm the data and approve the payment",
    "loading-payment": "We are processing your payment",
    "transaction-info-title": "Transaction details",
    "info-description-title": "Transaction information",
    "info-description-description": "This page could be reached in any moment.",
    "info-description-continue": "Continue...",
    "puntos_colombia-name": "Name",
    "puntos_colombia-fiscal_number_type": "Fiscal number type",
    "puntos_colombia-fiscal_number": "Fiscal number",
    "puntos_colombia-phone": "Phone",
    "payment-title-puntos_colombia": "Pay with Puntos Colombia",
    "colsubsidio-name": "Name",
    "colsubsidio-last_name": "Lastname",
    "colsubsidio-phone": "Phone",
    "colsubsidio-fiscal_number_type": "Fiscal number type",
    "pix-fiscal_number_type": "Fiscal number type",
    "pix-fiscal_number" : "Fiscal number",
    "colsubsidio-fiscal_number": "Fiscal number",
    "pse-fiscal_number_type": "Document type",
    "pse-fiscal_number": "Document number",
    "pse-type": "Person type",
    "pse-bank": "Bank",
    "pse-phone": "Phone",
    "pse-address": "Address",
    "safetypay-fiscal_number_type": "Document type",
    "safetypay-fiscal_number": "Document number",
    "safetypay-type": "Person type",
    "safetypay-bank": "Bank",
    "daviplata-fiscal_number_type": "Fiscal number type",
    "daviplata-fiscal_number": "Document number",
    "checkout-landing-title": "Purchase details",
    "checkout-accept-terms-and-conditions-text": "Accept",
    "checkout-accept-terms-and-conditions-link": "Personal data treatment Policy",
    "exchange-rate": "Exchange rate",

    /** INFO PAGE TRANSACTION */
    id: "Identification",
    description: "Description",
    amount: "Amount",
    total_amount: "Total amount",
    tip: "Tip",
    dev_reference: "Reference",
    rate: "Rate",
    payment_method: "Payment method",
    nit: "Identification number",
    status: "Status",
    authorization_date: "Authorization date",
    bank_code: "Bank code",
    bank_name: "Bank name",
    pse_cycle: "Cycle pse",
    paid_date: "Paid date",
    ticket_id: "Ticket identification",
    error_message: "ERROR MESSAGE", //Contactar a Adrian Montiel. Estos son detalles de PSE. Return message
    qr_error_message:
      "An error occurred while obtaining the Qr code, select another payment method or try later",
    order_error_message: "An error occurred getting the order, review your order link and try again",
    info_error_message:
      "An error occurred getting the order details, select another payment method or if you paid try later",
    details: "Details",
    trazability_code: "Traceability code",
    first_name: "Name",
    last_name: "Lastname",
    email: "E-mail",
    order: "Order",
    transaction: "Transaction",
    user: "User",
    agreement: "Agreement",
    reference: "Reference code",
    expiration_date: "Expiration date",
    "info-description-url_finalize": "Finish",
    "info-description-url_return": "Return to transaction...",
    "generate-payment-code-title": "Code to generate payment",
    "generate-payment-code-description":
      "The following code to pay your order will be valid until",
    "payment-code": "Code por payment",
    init: "Initiate",
    pending: "Pending",
    payed: "Paid",
    rejected: "Rejected",
    cancelled: "Cancelled",
    expired: "Expired",
    failed: "Failed",
    Card: "Credit card",
    card: "Credit card",
    Debit: "Debit card",
    debit: "Debit card",
    ClickToPay: "Click To Pay",
    clicktopay: "Click To Pay",
    number: "Number",
    type: "Type",
    fiscal_number: "Fiscal Number",
    installments: "Installments",
    trace_number: "Trace number",
    installments_type: "Installments type",
    carrier: "Carrier",
    message: "Message",
    payment_date: "Payment date",
    current_status: "Current status",
    authorization_code: "Authorization code",
    BankTransfer: "Bank transfer",
    Cash: "Cash payment",
    PSE: "Bank transfer",
    Init: "Initiate",
    Pending: "Pending",
    Payed: "Paid",
    Rejected: "Rejected",
    Cancelled: "Cancelled",
    Expired: "Expired",
    Failed: "Failed",
    approved: "Approved",
    failure: "Failure",
    success: "Success",
    goBack: "Back",
    "read-more": "Read more",
    "generate-payment-code-recommendation":
      "Your cash payment is pending, remember to keep in mind the following:",
    "generate-payment-code-recommendation-list": {
      1: "Check the list of points where you can make the purchase in the purchase summary",
      2: "Take the payment code of your purchase and the establishment code to make the payment",
      3: "You will receive a confirmation e-mail when the payment will be done.",
    },
    error: {
      "required-field": "Required field",
      "incorrect-pattern": "Does not match the allowed format",
    },
    "footer-title": "About Link to Pay",
    footer1: "Terms and conditions",
    footer2: "FAQs",
    footer3: "Contact and support",
    print: "Print",
    "info-landing-title": "Payment Summary",
    "payment-loading-description":
      "Processing the transaction with some payment method",
    verifyEwallet: "Ingrese su OTP (One Time Password)",
    validateCode: "Validar",
  },
  es: {
    currency: "Moneda",
    original_amount: "Monto original",
    paid_amount: "Pagado",
    remaining_amount: "Pendiente de pago",
    amount_with_points: "Monto con puntos",
    original_currency: "Moneda original",
    show: "Ingresar segunda clave",
    "show-label":
      "Por tu seguridad debes ingresar tu segunda clave de tu tarjeta multiservicios, a continuación",
    pay: "Pagar",
    expiration_days: "Días de expiración",
    product_description: "Descripción del producto",
    order_description: "Descripción del producto",
    payment_method_type: "Método de pago",
    url_reference: "Referencia de pago",
    partial_paid_link: "Link Pagado Parcialmente",
    "404-title": "Lo sentimos, no encontramos la página",
    "404-message":
      "La página que buscas o a la que fuiste redirigido, no puede ser encontrada",
    "order@user-name": "Nombre",
    "order@user-last_name": "Apellido",
    "order@user-email": "Correo electrónico",
    "order@order-dev_reference": "Número de referencia",
    "order@order-description": "Descripción",
    "order@order-amount": "Valor de la compra",
    "order@order-currency": "Moneda",
    "pick-payment-method": "Métodos de pago",
    "pick-expired-link": "Link expirado. Intenta generar uno nuevo",
    "pick-loading": "Cargando",
    "pick-payed-link": "Link pagado",
    payment_will_be: "Tu pago será de:",
    "paymentez-conf_invalid_card_type_message":
      "Tarjeta de crédito inválida para esta operación",
    "payment-title-pse": "Pago con PSE",
    "payment-title-safetypay": "Pago con Transferencia bancaria",
    "payment-title-cash": "Pago con efectivo",
    "payment-title-pix": "Pago con PIX",
    "payment-title-colsubsidio": "Colsubsidio",
    "payment-title-daviplata": "DaviPlata",
    "payment-method-card": "Pagar con Tarjeta",
    "payment-method-banktransfer": "Pago con PSE",
    "payment-method-cash": "Generar código de pago",
    "payment-description-paymentez":
      "Paga de forma segura usando tu tarjeta de crédito",
    "payment-description-pse":
      "Paymentez® procesa pagos locales en juegos de computador, sitios web y aplicaciones móviles en Latinoamérica. Estamos conectados con adquirientes locales de tarjetas de crédito en 6 países, ofrecemos transferencia bancaria y pagos en efectivo en cada país para proveer una plataforma de pagos amplia para nuestros clientes.",
    "payment-description-cash":
      "Paymentez® procesa pagos locales en juegos de computador, sitios web y aplicaciones móviles en Latinoamérica. Estamos conectados con adquirientes locales de tarjetas de crédito en 6 países, ofrecemos transferencia bancaria y pagos en efectivo en cada país para proveer una plataforma de pagos amplia para nuestros clientes.",
    "payment-action-button-paymentez": "Pagar con Tarjeta",
    "payment-action-button-paymentez-debit": "Pagar con Tarjeta de Debito",
    "payment-action-button-paymentez-clicktopay": "Pagar con Click To Pay",
    "payment-action-button-pse": "Pagar con PSE",
    "payment-action-button-cash": "Pagar con Efectivo",
    "payment-action-button-qr": "Pagar con Código Qr",
    "payment-action-button-pay-between-accounts": "Pago entre cuentas",
    "payment-action-button-pix": "Pagar con PIX",
    "payment-action-button-bancolombia": "Pagar con bancolombia",
    "payment-action-button-rappi": "Pagar con Rappi",
    "payment-action-button-safetypay": "Pagar con Tansferencia bancaria",
    "payment-action-button-colsubsidio": "Colsubsidio",
    "payment-action-button-brilla": "Pagar con Brilla",
    "payment-action-button-puntos_colombia": "Pagar con Puntos colombia",
    "payment-action-button-colsubsidio-modal": "Continuar",
    "payment-action-button-bradesco": "Pagar con Efectivo",
    "payment-action-button-daviplata": "DaviPlata",
    "payment-cancel-button": "Cancelar",
    "payment-next-button": "Continuar",
    "payment-title-step-safety": "Paso",
    "payment-title-description-safety":
      "A continuación serás redirigido a la página de Pago, recuerda tener en cuenta lo siguiente:",
    "payment-title-description-cash":
      "A continuación serás redirigido a la página de Pago con efectivo, recuerda tener en cuenta lo siguiente:",
    "payment-cash-recommend-1": "Se generará un código de pago para tu compra.",
    "payment-cash-recommend-2":
      "Aparecerá una lista de puntos en donde podrás realizar tu pago.",
    "payment-cash-recommend-3":
      "Lleva el código de pago de tu compra y el código del punto para realizar el pago.",
    "payment-cash-recommend-4":
      "Recibirás un correo de confirmación cuando realices tu pago.",
    "payment-title-description-qr": "Para poder completar el pago siga los siguientes pasos:",
    "payment-qr-recommend-1": "Ingresa a la billetera móvil, monedero o app de tu banco",
    "payment-qr-recommend-2": "Selecciona el medio de pago disponible en la entidad o monedero y escanea el código qr",
    "payment-qr-recommend-3": "Confirma los datos y selecciona en aprobar pago",
    "loading-payment": "Estamos procesando tu pago",
    "transaction-info-title": "Detalles de la transacción",
    "info-description-title": "Información de la transacción",
    "info-description-description":
      "Esta página puede ser consultada en cualquier momento.",
    "info-description-continue": "Continuar...",
    "puntos_colombia-name": "Nombre",
    "puntos_colombia-fiscal_number_type": "Tipo de documento",
    "puntos_colombia-fiscal_number": "Número de documento",
    "puntos_colombia-phone": "Teléfono",
    "payment-title-puntos_colombia": "Pagar con puntos Colombia",
    "colsubsidio-name": "Nombre",
    "colsubsidio-last_name": "Apellido",
    "colsubsidio-phone": "Teléfono",
    "colsubsidio-fiscal_number_type": "Tipo de documento",
    "colsubsidio-fiscal_number": "Número de documento",
    "pix-fiscal_number_type": "Tipo de documento",
    "pix-fiscal_number": "Número de documento",
    "bradesco-name": "Nombre",
    "bradesco-last_name": "Apellido",
    "bradesco-phone": "Teléfono",
    "bradesco-fiscal_number_type": "Tipo de documento",
    "bradesco-fiscal_number": "Número de documento",
    "pse-fiscal_number_type": "Tipo de documento",
    "pse-fiscal_number": "Número de documento",
    "pse-type": "Tipo de persona",
    "pse-bank": "Banco",
    "pse-phone": "Teléfono",
    "pse-address": "Dirección",
    "safetypay-fiscal_number_type": "Tipo de documento",
    "safetypay-fiscal_number": "Número de documento",
    "safetypay-type": "Tipo de persona",
    "safetypay-bank": "Banco",
    "daviplata-fiscal_number_type": "Tipo de documento",
    "daviplata-fiscal_number": "Número de documento",
    "checkout-landing-title": "Detalles de tu compra",
    "checkout-accept-terms-and-conditions-text": "Acepto",
    "checkout-accept-terms-and-conditions-link": "Política tratamiento datos Personales",

    "exchange-rate": "Tasa de cambio",

    /** INFO PAGE TRANSACTION */
    id: "Identificación",
    description: "Descripción",
    amount: "Monto",
    tip: "Propina",
    total_amount: "Monto total",
    dev_reference: "Referencia",
    rate: "Tasa",
    payment_method: "Método de pago",
    nit: "Número de identificación",
    status: "Estado",
    authorization_date: "Fecha de autorización",
    bank_code: "Código del banco",
    bank_name: "Nombre del Banco",
    pse_cycle: "Ciclo pse",
    paid_date: "Fecha de pago",
    ticket_id: "Identificación del ticket",
    qr_error_message:
      "Ocurrio un error al obtener el código Qr, seleccione otro método de pago o intente más tarde",
    order_error_message: "Ocurrio un error al obtener la orden, verifique la url que le proporcionaron e intente de nuevo",
    info_error_message:
      "Ocurrio un error, seleccione otro método de pago o  intente más tarde",
    error_message: "Detalles", //Contactar a Adrian Montiel. Estos son detalles de PSE.
    details: "Detalles",
    trazability_code: "Código de trazabilidad",
    first_name: "Nombre",
    last_name: "Apellido",
    email: "Correo electrónico",
    order: "Orden",
    transaction: "Transacción",
    user: "Usuario",
    agreement: "Convenio",
    reference: "Código para pago",
    expiration_date: "Fecha de expiración",
    "info-description-url_finalize": "Finalizar",
    "info-description-url_return": "Regresar a la transacción...",
    "generate-payment-code-title": "Código para generar pago",
    "generate-payment-code-description":
      "El siguiente código para pagar tu producto está vigente hasta",
    "payment-code": "Código para pago",
    init: "Iniciado",
    pending: "Pendiente",
    payed: "Pagado",
    rejected: "Rechazado",
    cancelled: "Cancelado",
    expired: "Expirado",
    failed: "Fallado",
    Card: "Tarjeta de crédito",
    card: "Tarjeta de crédito",
    Debit: "Tarjeta de débito",
    debit: "Tarjeta de débito",
    ClickToPay: "Click To Pay",
    clicktopay: "Click To Pay",
    number: "Número",
    type: "Tipo",
    fiscal_number: "Número de Documento",
    installments: "Cuotas",
    carrier: "Proveedor (carrier)",
    message: "Mensaje",
    payment_date: "Fecha de pago",
    trace_number: "Número de rastreo",
    installments_type: "Tipo de cuotas",
    authorization_code: "Código de autorización",
    current_status: "Estado actual",
    BankTransfer: "Transferencia bancaria",
    Cash: "Pago en efectivo",
    PSE: "Pago con PSE",
    Init: "Iniciado",
    Pending: "Pendiente",
    Payed: "Pagado",
    Rejected: "Rechazado",
    Cancelled: "Cancelado",
    Expired: "Expirado",
    Failed: "Fallida",
    approved: "Aprobada",
    failure: "Fallida",
    success: "Aprobada",
    goBack: "Regresar",
    "read-more": "Leer más",
    "generate-payment-code-recommendation":
      "Tu pago en efectivo se encuentra pendiente, recuerda tener en cuenta lo siguiente:",
    "generate-payment-code-recommendation-list": {
      1: "Consulta en el resumen de la compra la lista de puntos en donde podrás realizar tu pago.",
      2: "Lleva el código de pago de tu compra y el código del punto para realizar el pago.",
      3: "Recibirás un correo de confirmación cuando realices tu pago.",
    },
    error: {
      "required-field": "Este campo es requerido",
      "incorrect-pattern": "No coincide con el formato permitido",
    },
    "footer-title": "Sobre Link to Pay",
    footer1: "Términos y condiciones de uso",
    footer2: "Preguntas frecuentes",
    footer3: "Contacto y soporte",
    print: "Imprimir",
    "info-landing-title": "Resumen de tu compra",
    "payment-loading-description":
      "La transacción se esta procesando con algun métodos de pago",
    verifyEwallet: "Ingrese su OTP (One Time Password)",
    validateCode: "Validar",
  },
  pt: {
    currency: "Moeda",
    paid_amount: "Pago",
    remaining_amount: "Pendente de pagamento",
    amount_with_points: "quantidade com pontos",
    original_amount: "Quantidade original",
    original_currency: "Moeda original",
    show: "Digite a segunda senha",
    "show-label":
      "Para sua segurança, você deve inserir sua segunda senha para o seu cartão multisserviço, abaixo",
    pay: "Pagar",
    status: "Estado",
    expiration_days: "Dias de vencimento",
    product_description: "Descrição do produto",
    order_description: "Descrição do produto",
    payment_method_type: "Forma de pagamento",
    url_reference: "Referência de pagamento",
    "404-title": "Desculpe, não conseguimos a página",
    "404-message":
      "A página que você está procurando ou que foi redirecionado não pode ser encontrada",
    "order@user-name": "Nome",
    "order@user-last_name": "Sobrenome",
    "order@user-email": "E-mail",
    "order@order-dev_reference": "Número de referência",
    "order@order-description": "Descrição",
    "order@order-amount": "Valor da compra",
    "order@order-currency": "Moeda",
    "pick-payment-method": "Formas de pagamento",
    "pick-expired-link": "Link expirado. Tente gerar um novo",
    partial_paid_link: "Link pagado em parte",
    "pick-loading": "Cargando",
    "pick-payed-link": "Link pagado",
    payment_will_be: "Seu pagamento será:",
    "paymentez-conf_invalid_card_type_message":
      "Cartão de crédito inválido para esta operação",
    "payment-title-pse": "Pagamento com transferência bancária",
    "payment-title-safetypay": "Pagamento com transferência bancária",
    "payment-title-cash": "Pagamento com dinheiro",
    "payment-title-pix": "Pagamento com PIX",
    "payment-title-colsubsidio": "Colsubsidio",
    "payment-title-daviplata": "DaviPlata",
    "payment-method-card": "Pagar com cartão de crédito",
    "payment-method-banktransfer": "Pagamento com transferência bancária",
    "payment-method-cash": "Gerar código de pagamento",
    "payment-description-paymentez":
      "Pague com segurança usando seu cartão de crédito",
    "payment-description-pse":
      "Paymentez® processa pagamentos locais em jogos de computador, sites e aplicativos móveis na América Latina. Estamos conectados com emissores locais de cartões de crédito em 6 países, oferecemos transferências bancárias e pagamentos em dinheiro em cada país para fornecer uma plataforma abrangente de pagamento para nossos clientes.",
    "payment-description-cash":
      "Paymentez® processa pagamentos locais em jogos de computador, sites e aplicativos móveis na América Latina. Estamos conectados com emissores locais de cartões de crédito em 6 países, oferecemos transferências bancárias e pagamentos em dinheiro em cada país para fornecer uma plataforma abrangente de pagamento para nossos clientes.",
    "payment-action-button-paymentez": "Pagar com cartão de crédito",
    "payment-action-button-paymentez-debit": "Pagar com cartão de débito",
    "payment-action-button-paymentez-clicktopay": "Pagar com Click To Pay",
    "payment-action-button-pse": "Pagar com transferência bancária",
    "payment-action-button-cash": "Pagar com dinheiro",
    "payment-action-button-colsubsidio": "Colsubsidio",
    "payment-action-button-brilla": "Pagar com Brilla",
    "payment-action-button-puntos_colombia": "Pagar com Pontos colombia",
    "payment-action-button-pix": "Pagar com PIX",
    "payment-action-button-colsubsidio-modal": "Continue",
    "payment-action-button-bradesco": "Pagar com dinheiro",
    "payment-action-button-rappi": "Pagar com rappi",
    "payment-action-button-bancolombia": "Pagar com Bancolombia",
    "payment-action-button-qr": "Pagar com Código Qr",
    "payment-action-button-pay-between-accounts": "Pagamento entre contas",
    "payment-action-button-daviplata": "DaviPlata",
    "payment-action-button-safetypay": "Pagar com transferência bancária",
    "payment-cancel-button": "Cancelar",
    "payment-next-button": "Continue",
    "payment-title-step-safety": "Paso",
    "payment-title-description-safety":
      "Você será redirecionado para a página Pagamento. Lembre-se de considerar o seguinte:",
    "payment-title-description-cash":
      "Você será redirecionado para a página Pagamento em dinheiro. Lembre-se de considerar o seguinte:",
    "payment-cash-recommend-1":
      "Um código de pagamento será gerado para sua compra.",
    "payment-cash-recommend-2":
      "Uma lista de pontos aparecerá onde você poderá efetuar seu pagamento",
    "payment-cash-recommend-3":
      "Pegue o código de pagamento da sua compra e o código do ponto para efetuar o pagamento.",
    "payment-cash-recommend-4":
      "Você receberá um e-mail de confirmação quando efetuar seu pagamento.",
    "payment-title-description-qr": "Para concluir o pagamento, siga os passos abaixo:",
    "payment-qr-recommend-1": "Acesse a carteira móvel, bolsa ou aplicativo do seu banco",
    "payment-qr-recommend-2": "Selecione o método de pagamento disponível na entidade ou carteira e digitalize o código qr",
    "payment-qr-recommend-3": "Confirme os dados e aprove o pagamento",
    "loading-payment": "Estamos processando seu pagamento",
    "transaction-info-title": "Detalhes da transação",
    "info-description-title": "Informações sobre transações",
    "info-description-description":
      "Esta página pode ser consultada a qualquer momento.",
    "info-description-continue": "Continuar...",
    "puntos_colombia-name": "Nome",
    "puntos_colombia-fiscal_number_type": "Tipo de documento",
    "puntos_colombia-fiscal_number": "Número do documento",
    "puntos_colombia-phone": "Telefone",
    "payment-title-puntos_colombia": "Pagar com puntos Colombia",
    "colsubsidio-name": "Nome",
    "colsubsidio-last_name": "Sobrenome",
    "colsubsidio-phone": "Telefone",
    "colsubsidio-fiscal_number_type": "Tipo de documento",
    "colsubsidio-fiscal_number": "Número de documento",
    "pix-fiscal_number_type": "Tipo de documento",
    "pix-fiscal_number": "Numero de documento",
    "payment-title-bradesco": "Boleto Bancário",
    "bradesco-name": "Nome",
    "bradesco-last_name": "Sobrenome",
    "bradesco-phone": "Teléfono",
    "bradesco-fiscal_number_type": "Tipo de documento",
    "bradesco-fiscal_number": "CPF",
    "bradesco-additional_address_info": "Complemento",
    "bradesco-house_number": "Número",
    "bradesco-state": "Estado",
    "bradesco-zip_code": "CEP",
    "bradesco-city": "Cidade",
    "bradesco-district": "Bairro",
    "bradesco-street": "Endereço",
    "pse-fiscal_number_type": "Tipo de documento",
    "pse-fiscal_number": "Número de documento",
    "pse-type": "Tipo de pessoa",
    "pse-bank": "Banco",
    "pse-phone": "Telefone",
    "pse-address": "Endereço",
    "daviplata-fiscal_number_type": "Tipo de documento",
    "daviplata-fiscal_number": "Número de documento",
    "checkout-landing-title": "Detalhes da sua compra",
    "checkout-accept-terms-and-conditions-text": "Eu aceito os",
    "checkout-accept-terms-and-conditions-link": "Política de tratamento de dados pessoais",
    "exchange-rate": "Taxa de câmbio",

    /** INFO PAGE TRANSACTION */
    id: "Identificação",
    description: "Descrição",
    amount: "Valor",
    total_amount: "Total amount",
    tip: "Tip",
    dev_reference: "Referência",
    rate: "Taxa",
    payment_method: "Forma de pagamento",
    nit: "Número de identificação",
    authorization_date: "Fecha de autorización",
    bank_code: "Código do banco",
    bank_name: "Nome do banco",
    pse_cycle: "Ciclo pse",
    paid_date: "Data de pagamento",
    ticket_id: "Identificação da compra",
    qr_error_message:
      "Ocorreu um erro ao obter o código Qr, selecione outro método de pagamento ou tente mais tarde",
    order_error_message: "ocorrer um erro ao obter o pedido, verifique a url e tente de novo",
    info_error_message:
      "Ocorreu um erro, selecione outro método de pagamento ou se você efetuou o pagamento, tente novamente mais tarde",
    error_message: "Detalles", //Contactar a Adrian Montiel. Estos son detalles de PSE. Detalhes
    details: "Detalhes",
    trazability_code: "Código de rastreio",
    first_name: "Nome",
    last_name: "Sobrenome",
    email: "E-mail",
    order: "Pedido",
    transaction: "Transação",
    user: "Usuário",
    agreement: "Acordo",
    reference: "Código de pagamento",
    expiration_date: "Data de validade",
    "info-description-url_finalize": "Concluir",
    "info-description-url_return": "Retornar",
    "generate-payment-code-title": "Código para gerar o pagamento",
    "generate-payment-code-description":
      "Para pagar o seu pedido use esse código válido até",
    "payment-code": "Código para pagamento",
    init: "Iniciado",
    pending: "Pendente",
    payed: "Pago",
    rejected: "Rejeitado",
    cancelled: "Cancelado",
    expired: "Expirado",
    failed: "Falhou",
    Card: "Cartão de crédito",
    card: "Cartão de crédito",
    Debit: "Cartão de débito",
    debit: "Cartão de débito",
    ClickToPay: "Click To Pay",
    clicktopay: "Click To Pay",
    number: "Número",
    type: "Tipo",
    fiscal_number: "Número do Documento",
    installments: "Parcelas",
    trace_number: "Número de rastreamento",
    installments_type: "Tipos de parcelas",
    carrier: "Provedor (carrier)",
    message: "Mensagem",
    payment_date: "Data de pagamento",
    current_status: "Status atual",
    authorization_code: "Código de autorização",
    BankTransfer: "Transferência bancária",
    Cash: "Pagamento em dinheiro",
    PSE: "Pagamento com transferência bancária",
    Init: "Iniciando",
    Pending: "Pendente",
    Payed: "Pago",
    Rejected: "Rejeitado",
    Cancelled: "Cancelado",
    Expired: "Expirado",
    Failed: "Falhou",
    approved: "Aprovado",
    failure: "Falhou",
    success: "Aprovado",
    goBack: "Retornar",
    "read-more": "Ler mais",
    "generate-payment-code-recommendation":
      "Seu pagamento em dinheiro está pendente, lembre-se de considerar o seguinte",
    "generate-payment-code-recommendation-list": {
      1: "Verifique o resumo da compra para obter a lista de pontos em que você pode efetuar seu pagamento.",
      2: "Pegue o código de pagamento da sua compra e o código do ponto para efetuar o pagamento",
      3: "Você receberá um email de confirmação quando efetuar seu pagamento",
    },
    error: {
      "required-field": "Este campo é obrigatório.",
      "incorrect-pattern": "Não corresponde ao formato permitido",
    },
    "footer-title": "Sobre o Link to Pay",
    footer1: "Termos e condições de uso",
    footer2: "Perguntas frequentes",
    footer3: "Contato e suporte",
    print: "Salvar",
    "info-landing-title": "Resumo da sua compra",
    "payment-loading-description":
      "A transação está sendo processada com algumas formas de pagamento",
    verifyEwallet: "Ingrese su OTP (One Time Password)",
    validateCode: "Validar",
  },
});

export const getFirstBrowserLanguage = function () {
  let nav = window.navigator,
    browserLanguagePropertyKeys = [
      "language",
      "browserLanguage",
      "systemLanguage",
      "userLanguage",
    ],
    i,
    language;

  // support for HTML 5.1 "navigator.languages"
  if (Array.isArray(nav.languages)) {
    for (i = 0; i < nav.languages.length; i++) {
      language = nav.languages[i];
      if (language && language.length) {
        return language;
      }
    }
  }

  // support for other well known properties in browsers
  for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
    language = nav[browserLanguagePropertyKeys[i]];
    if (language && language.length) {
      return language;
    }
  }

  return null;
};

export const lang = getFirstBrowserLanguage().split("-");

const configs = store.get("linktopay") || {};

const langDefault =
  configs.lang === "es" || configs.lang === "pt" ? configs.lang : "en";

I18n.setLocale(langDefault || lang[0] || "es");
