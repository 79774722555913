import { MDBBtn, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader } from 'mdbreact';
import propTypes from "prop-types";
import React, { Component } from 'react';
import { I18n } from 'react-i18nify';
import ReactForm from "react-jsonschema-form";
import { connect } from 'react-redux';
import { asyncfetchOtherPaymentStatus } from '../../actions/serverAnswerActions';
import { multiUpdateSingleMulti } from '../../utils/convertMultiLevelIntoSingle';
import { formInterpreterSafetypay } from '../../utils/formInterpreterSafetypay';
import { schemaTranspiler } from '../../utils/schemaTranspiler';
import ModalPaymentLoading from '../PageBuilder/ModalPaymentLoading';
import { TransformErrors } from '../../utils/TransformErrors';
import BttnPayment from '../PageBuilder/BttnPayment';


export class BanktrasferPaymentComponent extends Component {
  state = {
    modalLoading: false,
    isPaying: false
  }

  constructor() {
    super();
    this.modalForm = this.modalForm.bind(this);
    this.DoTogglePayment = this.DoTogglePayment.bind(this);
    this.myRef = React.createRef();
  }

  sendData = (formData = { formData: undefined }) => {
    const { url } = this.props.payment;
    const { responseStatus, orderDetails, orderData } = this.props;
    this.DoTogglePayment();
    this.DoToggle();

    const newData = multiUpdateSingleMulti(orderData, orderDetails.data);
    const newOrderData = {
      ...orderDetails, data: { ...newData }
    }

    const dataForm = formInterpreterSafetypay(newOrderData, formData.formData);
    responseStatus(url, dataForm)
  }

  DoToggle = () => {
    this.setState((prevState) => {
      const newState = !prevState.modalLoading;
      return { modalLoading: newState }
    });
  }

  DoTogglePayment = () => {
    this.setState((prevState) => {
      const newState = !prevState.isPaying;
      return { isPaying: newState }
    });
  }

  modalForm() {
    const { orderId, payment, typeOrder = "" , currentTheme} = this.props;
    let { isPaying } = this.state;
    const formId = `${typeOrder}-payment`;
    const { sendData, DoTogglePayment } = this;
    return (
      <MDBModal className="modal-notify" isOpen={isPaying} size="lg" toggle={DoTogglePayment}>
        <MDBModalHeader toggle={DoTogglePayment}>
          <strong>{I18n.t(`payment-title-${typeOrder}`)}</strong>
        </MDBModalHeader>
        <MDBModalBody>
          <div className="row" key={`${orderId}`}>
            <div className="col-12">
              {
                (() => {
                  const { extra_fields } = payment;
                  if (extra_fields && Object.entries(extra_fields).length > 0) {
                    const schema = schemaTranspiler(extra_fields, `${typeOrder}`);
                    return <ReactForm {...schema}
                                      showErrorList={false}
                                      transformErrors={TransformErrors}
                                      id={formId}
                                      name={formId}
                                      idPrefix={`${typeOrder}`}
                                      ref={input => this.myRef = input}
                                      onSubmit={sendData}>
                      <div>
                        <MDBBtn size="lg" className="d-none" type="submit" color={"none"}>
                          {I18n.t(`payment-action-button-${typeOrder}`)}
                        </MDBBtn>
                      </div>
                    </ReactForm>
                  } else {
                    return (
                      <>
                        <span>{I18n.t(`payment-description-${typeOrder}`)}</span>
                      </>
                    )
                  }
                })()
              }
            </div>
          </div>
        </MDBModalBody>
        <MDBModalFooter className="">
          <MDBBtn color="grey" onClick={DoTogglePayment}>{I18n.t(`payment-cancel-button`)}</MDBBtn>
          <MDBBtn color="none"
                  style={{ "background": currentTheme.primary_color }}
                  onClick={() => { this.myRef.submit() }}>{I18n.t(`payment-action-button-${typeOrder}`)}</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    )
  }

  render() {
    const { typeOrder = "", currentTheme, disabled } = this.props;
    const { DoToggle, DoTogglePayment, modalForm } = this;
    const { modalLoading } = this.state;
    return (<>
      {modalForm()}
      <ModalPaymentLoading {...{ modalLoading, typeOrder, DoToggle }} />
      <div className="full-centering">
        <BttnPayment 
          disabled={disabled}
          currentTheme={currentTheme}
          internationalization={I18n.t(`payment-action-button-${typeOrder}`)}
          className="enlace"
          showForm={DoTogglePayment} // onClick en el componente de botón
        />
      </div>
    </>);
  }
}

BanktrasferPaymentComponent.propTypes = {
  orderId: propTypes.string,
  currentTheme: propTypes.object,
  typeOrder: propTypes.string,
  color: propTypes.string,
  payment: propTypes.object,
  responseStatus: propTypes.any,
  orderDetails: propTypes.object,
  disabled: propTypes.bool,
}

const mapStateToProps = (state) => ({ //state, ownProps
  currentTheme: state.theme,
  orderDetails: state.detalles
});

const mapDispatchToProps = (dispatch) => ({
  responseStatus: (url, data) => dispatch(asyncfetchOtherPaymentStatus(url, data)),
})

const ConnectedBanktransferPaymentComponent = connect(
  mapStateToProps, mapDispatchToProps
)(BanktrasferPaymentComponent)

export default ConnectedBanktransferPaymentComponent;
