import { splitSelectValueLabel } from './schemaTranspiler';

/**
 *
 * @param {*} formData Data incoming from a form filled by the user. It is already validated
 * @param {*} extreParams OrderDetails
 * @returns
{
  "bank": {
            "code": 1022,
            "name": "BANCO"
        },
    "user": {
      "id": "117",
      "email": "gcruz@paymentez.com",
      "name": "Gabriel",
      "last_name": "Cruz",
      "fiscal_number_type": "CC",
      "fiscal_number": 123123123,
            "type": "N"
    },
    "order": {
      "dev_reference": "1",
      "description": "Quiero un producto",
      "amount": 1000.0,
      "installments": null,
      "installments_type": 1,
      "currency": "COP",
      "vat": 0.0
    }
}
 */

export const colsubsidioFormInterpreter = (extreParams, formData = {}) => {
    const { user, order } = extreParams.data;
    const { name, last_name, phone, fiscal_number_type, fiscal_number, type } = formData;
    let response = {
        user: {
            ...user,
            fiscal_number_type: fiscal_number_type ? splitSelectValueLabel(fiscal_number_type).value : null,
            fiscal_number,
            type: type ? splitSelectValueLabel(type).value : null,
            name,
            last_name,
            phone
        },
        order: { ...order }
    }
    return response;
}

/*
{
	"user": {
		"id": "117",
		"email": "test@paymentez.com",
		"name": "Tes",
		"last_name": "Morena",
		"phone": 234566234,
		"fiscal_number_type": "CC",
		"fiscal_number": 123123123
	}
}

{
  "success": true,
  "detail": "Operation completed successfully.",
  "data": {
    "url_redirect": null,
    "router_redirect": "/transaction/info/1YdmGKO"
  }
}
 */
