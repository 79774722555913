import {splitSelectValueLabel} from './schemaTranspiler';

export const formInterpreterPix = (extraParams, formData = {}) => {
  const {user, order} = extraParams.data;
  const {fiscal_number_type, fiscal_number} = formData;
  let response = {
    payment: {
      ...extraParams.data.payment.pix
    },
    user: {
      ...user,
      fiscal_number_type: fiscal_number_type ? splitSelectValueLabel(fiscal_number_type).value : null,
      fiscal_number
    },
    order: {...order}
  }
  return response;
}
