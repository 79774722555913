import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  asyncFetchPostServerPaymanetezClickToPayStatus,
  changeServerData,
  fetchGetServerData,
  fetchOtherPaymentStatus
} from '../../actions/serverAnswerActions.js';
import { INFORMATION_TRANSACTION, PAYMENTEZ_API_ENV, REFERENCE_TRANSACTION } from "../../constants/endpoints";
import BttnPayment from '../PageBuilder/BttnPayment'
import { I18n } from 'react-i18nify';
import { asyncChangeDetailsOrder } from '../../actions/detallesCompraActions';
//require('../../lib/paymentez/paymentez-checkout.min.js');

export const PaymentClickToPayPaymentez = ({ detalles, currentTheme, orderData, orderId, children = undefined, responseStatus, typeOrder, disabled, fecthOrderData }) => {

    const client_app = {
        client_app_code: detalles.data.payment.clicktopay.app_code,//'LINKTOPAY-CLIENT', // Client Credentials Provied by Paymentez,
        client_app_key: detalles.data.payment.clicktopay.app_key, // Client Credentials Provied by Paymentez,
        locale: I18n._localeKey, // User's preferred language (es, en, pt). English will be used by default.
        env_mode: PAYMENTEZ_API_ENV, // `prod`, `stg`, `local` to change environment. Default is `stg`
    }

    const data_ltp = {
      theme: currentTheme,
      user: {
        email: orderData["user-email"],
        name: orderData["user-name"],
        last_name: orderData["user-last_name"]
      }
    }


  const showForm = () => {
        /* eslint-disable no-undef */
        /*eslint no-console: ["error", { allow: ["warn", "error"] }] */
        fetchGetServerData(`${INFORMATION_TRANSACTION}${orderId}`)
                .then(response => response.json())
                .then((data) => {
                    if(data.data.order.status === "Expired" || data.data.order.status === "Payed") {
                      fecthOrderData(orderId);
                    }
                    else {
                        var paymentCheckout = new PaymentCheckout.modal({ // eslint-disable-line no-use-before-define
                            ...client_app,
                            onOpen: function () {
                                //console.log('modal open')
                            },
                            onClose: function () {
                                //console.log('modal closed')
                            },
                            onResponse: function (response) {
                                responseStatus(response, orderId, orderData, detalles.data);
                            }
                        });

                        fetchOtherPaymentStatus(`${REFERENCE_TRANSACTION}${orderId}/`, data_ltp)
                          .then(response => response.json())
                          .then((data_checkout) => {
                            paymentCheckout.open({
                              reference: data_checkout.data.reference
                            });
                          })
                          .catch(error => {
                            console.error(error);
                            return dispatch(changeServerData(error))
                        });

                        window.addEventListener('popstate', function () {
                            paymentCheckout.close();
                        });
                        /* eslint-enable no-undef */
                    }
                })
    }
    return children ?
        React.cloneElement(children, { onClick: showForm }) :
        <BttnPayment
        disabled={disabled}
            currentTheme={currentTheme}
            internationalization={I18n.t(`payment-action-button-${typeOrder}`)}
            className="enlace"
            showForm={showForm} // onClick en el componente de botón
        />
}

const mapStateToProps = (state) => ({ //state, ownProps
    currentTheme: state.theme,
    detalles: state.detalles
});

const mapDisptachToProps = (dispatch) => ({
    responseStatus: (response, orderId, order_details) => dispatch(asyncFetchPostServerPaymanetezClickToPayStatus(response, orderId, order_details)),
    fecthOrderData: (orderId) => {
        dispatch(asyncChangeDetailsOrder(orderId));
      }
})

PaymentClickToPayPaymentez.propTypes = {
    detalles: PropTypes.object,
    orderId: PropTypes.string,
    children: PropTypes.any,
    responseStatus: PropTypes.any,
    orderData: PropTypes.object,
    currentTheme: PropTypes.object,
    typeOrder: PropTypes.string,
    disabled: PropTypes.bool,
    fecthOrderData: PropTypes.any,
};

const conectedPaymentClickToPayPaymentez = connect(
    mapStateToProps,
    mapDisptachToProps
)(PaymentClickToPayPaymentez);

export default conectedPaymentClickToPayPaymentez;
