import {splitSelectValueLabel} from "./schemaTranspiler";

export const formInterpreter = (payment, extraParams, formData = {}) => {
  const {user, order} = extraParams.data;
  const carrier_name = payment.carrier

  let response = {}
  switch (carrier_name) {
    case "puntos_colombia": {
      const {name, fiscal_number_type, fiscal_number, phone} = formData;
      response = {
        user: {
          ...user,
          name: name,
          fiscal_number_type: fiscal_number_type ? splitSelectValueLabel(fiscal_number_type).value : null,
          fiscal_number,
          phone
        },
        order: {...order}
      }
      break;
    }
    default: {
      response = {
        user: {
          ...user,
          ...formData
        },
        order: {...order}
      }
    }
  }

  return response;
}
