import { MDBBtn, MDBRow, MDBCol, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader } from 'mdbreact';
import propTypes from "prop-types";
import React, { Component } from 'react';
import { I18n } from 'react-i18nify';
import ReactForm from "react-jsonschema-form";
import { connect } from 'react-redux';
import { asyncfetchOtherPaymentStatus } from '../../actions/serverAnswerActions';
//import JSONPretty from 'react-json-pretty';
import { multiUpdateSingleMulti } from '../../utils/convertMultiLevelIntoSingle';
import { daviplataFormInterpreter } from '../../utils/daviplataFormInterpreter';
import { schemaTranspiler } from '../../utils/schemaTranspiler';
import ModalPaymentLoading from '../PageBuilder/ModalPaymentLoading';
import { TransformErrors } from '../../utils/TransformErrors';
import BttnPayment from '../PageBuilder/BttnPayment';


export class DaviplataPaymentComponent extends Component {
    state = {
        modalLoading: false,
        isPaying: false
    }

    constructor() {
        super();
        this.modalForm = this.modalForm.bind(this);
        this.DoTogglePayment = this.DoTogglePayment.bind(this);
        this.myRef = React.createRef();
    }

    sendData = (formData = { formData: undefined }) => {
        const { url } = this.props.payment ;
        const { responseStatus, orderDetails, orderData } = this.props;
        this.DoTogglePayment();
        this.DoToggle();

        const newData = multiUpdateSingleMulti(orderData, orderDetails.data);
        const newOrderData = {
            ...orderDetails, data: { ...newData }
        }

        const dataForm = daviplataFormInterpreter(newOrderData, formData.formData);
        responseStatus(url, dataForm)
    }

    DoToggle = () => {
        this.setState((prevState) => {
            const newState = !prevState.modalLoading;
            return { modalLoading: newState }
        });
    }

    DoTogglePayment = () => {
        this.setState((prevState) => {
            const newState = !prevState.isPaying;
            return { isPaying: newState }
        });
    }

    modalForm() {
        const { orderId, payment, typeOrder = "" } = this.props;
        let { isPaying } = this.state;
        const formId = `${typeOrder}-payment`;
        const { sendData, DoTogglePayment } = this;
        return (
            <MDBModal className="modal-notify" isOpen={isPaying} size="lg" toggle={DoTogglePayment}>
                <MDBModalHeader toggle={DoTogglePayment}>
                    {/* <strong>{I18n.t(`payment-title-${typeOrder}`)}</strong> */}
                    <div className="daviplata_logo">
                        <img src="https://s3.amazonaws.com/cdn.images/cc/image/Daviplata-logo.png" alt="logo" width="100%" />
                    </div>
                </MDBModalHeader>
                <MDBModalBody>
                    <div className="row" key={`${orderId}`}>
                        <div className="col-12">
                            {
                                (() => {
                                    const { extra_fields } = payment;
                                    if (extra_fields && Object.entries(extra_fields).length > 0) {
                                        const schema = schemaTranspiler(extra_fields, `${typeOrder}`);
                                        return <ReactForm {...schema}
                                            showErrorList={false}
                                            transformErrors={TransformErrors}
                                            id={formId}
                                            name={formId}
                                            idPrefix={`${typeOrder}`}
                                            ref={input => this.myRef = input}
                                            onSubmit={sendData}>
                                            <div>
                                                <MDBBtn size="lg" className="d-none" type="submit" color={"none"}>
                                                    {I18n.t(`payment-action-button-${typeOrder}`)}
                                                </MDBBtn>
                                            </div>
                                        </ReactForm>
                                    } else {
                                        return (
                                            <>
                                                {/*} <JSONPretty id="json-pretty" data={payment}></JSONPretty> {*/}
                                                <span>{I18n.t(`payment-description-${typeOrder}`)}</span>
                                            </>
                                        )
                                    }
                                })()
                            }
                        </div>
                    </div>
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBRow className="daviplata_footer">
                        <MDBCol sm="12" md="6" className="daviplata_cancel">
                            <MDBBtn style={{width: '100%'}} color="grey" onClick={DoTogglePayment}>{I18n.t(`payment-cancel-button`)}</MDBBtn>
                        </MDBCol>
                        <MDBCol sm="12" md="6" className="daviplata_pay">
                            <MDBBtn style={{width: '100%'}} color="blue" onClick={() => { this.myRef.submit() }}>{I18n.t(`pay`)}</MDBBtn>
                        </MDBCol>
                        {/* <MDBCol md="4" className="pse_img">
                            <div style={{width: '50%'}}>
                                <Image
                                    className={`mb-3`}
                                    src={logo}
                                    style={{width: '100%'}}
                                />
                            </div>
                        </MDBCol> */}
                    </MDBRow>
                </MDBModalFooter>
            </MDBModal>
        )
    }

    render() {
        const { logo = "", typeOrder = "", currentTheme, disabled } = this.props;
        const { DoToggle, DoTogglePayment, modalForm } = this;
        const { modalLoading } = this.state;
        return (<>
            {modalForm()}
            <ModalPaymentLoading {...{ modalLoading, typeOrder, logo, DoToggle }} />
            <div className="full-centering">
                <BttnPayment 
                    disabled={disabled}
                    currentTheme={currentTheme}
                    internationalization={I18n.t(`payment-action-button-${typeOrder}`)}
                    showForm={DoTogglePayment} // onClick en el componente de botón
                />
            </div>
        </>);
    }
}

DaviplataPaymentComponent.propTypes = {
    orderId: propTypes.string,
    currentTheme: propTypes.object,
    typeOrder: propTypes.string,
    color: propTypes.string,
    logo: propTypes.string,
    payment: propTypes.object,
    responseStatus: propTypes.any,
    orderDetails: propTypes.object,
    disabled: propTypes.bool,
}

const mapStateToProps = (state) => ({ //state, ownProps
    currentTheme: state.theme,
    orderDetails: state.detalles
});

const mapDispatchToProps = (dispatch) => ({
    responseStatus: (url, data) => dispatch(asyncfetchOtherPaymentStatus(url, data)),
})

const ConnectedDaviplataPaymentComponent = connect(
    mapStateToProps, mapDispatchToProps
)(DaviplataPaymentComponent)

export default ConnectedDaviplataPaymentComponent;