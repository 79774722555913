import { splitSelectValueLabel } from './schemaTranspiler';

/**
 *
 * @param {*} formData Data incoming from a form filled by the user. It is already validated
 * @param {*} extreParams OrderDetails
 * @returns
{
  "bank": {
            "code": 1022,
            "name": "BANCO"
        },
    "user": {
      "id": "117",
      "email": "gcruz@paymentez.com",
      "name": "Gabriel",
      "last_name": "Cruz",
      "fiscal_number_type": "CC",
      "fiscal_number": 123123123,
            "type": "N"
    },
    "order": {
      "dev_reference": "1",
      "description": "Quiero un producto",
      "amount": 1000.0,
      "installments": null,
      "installments_type": 1,
      "currency": "COP",
      "vat": 0.0
    }
}
 */

export const bradescoFormInterpreter = (extreParams, formData = {}) => {
  const { user, order } = extreParams.data;
  const { 
    fiscal_number_type = null, 
    type = null,
    fiscal_number = null,
    city = null,
    zip_code = null,
    street = null,
    state = null,
    house_number = null,
    district = null,
    additional_address_info = null
  } = formData;

  const address = {
    state: state ? splitSelectValueLabel(state).value : null,
    city: city,
    zip_code: zip_code,
    street: street,
    house_number: house_number,
    district: district,
    additional_address_info: additional_address_info,

  };

  //clear null elements
  Object.keys(address).forEach((k) => address[k] == null && delete address[k]);

  let request_params = {
    user: {
      ...user,
      fiscal_number_type: fiscal_number_type ? splitSelectValueLabel(fiscal_number_type).value : null,
      fiscal_number: fiscal_number,
      type: type ? splitSelectValueLabel(type).value : null,
      address,
    },
    order: { ...order }
  }
  /*
  address: {
        state: "SP",
        city: "Sao Paulo",
        zip: "01418000",
        street: "Alameda Santos",
        house_number: "200",
        district: "Cerqueira",
        additional_address_info: "",

      }

  {name: "23123", last_name: "3123123", zip: "3123123", state: "AC@Acre", city: "213123", …}
    additional_address_info: "3123123132"
    city: "213123"
    district: "123123"
    fiscal_number: "1111"
    house_number: "3123123"
    last_name: "3123123"
    name: "23123"
    state: "AC@Acre"
    street: "312312312"
    zip: "3123123"
    __proto__: Object
   */
  return request_params;
}
